export default {
  apiBaseUrl: "https://uncleassist.xyz/api/",
  accessTokenKey: "Authorization",
  bearerPrefix: "bearer",
  accessDeniedPath: "/access-denied",
  expires: 365,
  secure: true
};
// export default {
//   apiBaseUrl: 'http://192.168.2.110:3000/api/',
//   accessTokenKey: 'Authorization',
//   bearerPrefix: 'bearer',
//   accessDeniedPath: '/access-denied',
//   expires: 365,
//   secure: false
// };
