import { useCallback, useContext, useEffect, useState } from 'react';
import api from '../../../common/api/api';
import {
  useApiDelete,
  useApiGet,
  useApiPatch,
  useApiPost,
  useApiPut
} from '../../../common/api/Api.hooks';
import { PaginatedList } from '../../../common/PaginatedList.interface';

export function useFindSubscriptionTypes() {
  const { data, isLoading, error, get } = useApiGet<any>('subscription/type');
  return { subscriptionTypes: data, isLoading, error, get };
}

export function useFindSubscriptionHistory() {
  const { data, isLoading, error, get } = useApiGet<any>('subscription/history');
  return { response: data, isLoading, error, get };
}

export function useDeleteSubscriptionHistory() {
  const { data, isLoading, error, del } = useApiDelete<any>('subscription/history');
  return { response: data, isLoading, error, del };
}

export function useCreateSubscription() {
  const { data, isLoading, error, post } = useApiPost<any>(
    'subscription'
  );
  return { data, isLoading, error, post };
}

export function useCreateSubscriptionType() {
  const { data, isLoading, error, post } = useApiPost<any>(
    'subscription/type'
  );
  return { data, isLoading, error, post };
}

export function useUpdateSubscriptionType(id: number) {
  const { data, isLoading, error, put } = useApiPut<any>(
    `subscription/type/${id}`
  );
  return { updated: data, isLoading, error, put };
}

export function useCreateStripeCheckoutSession() {
  const { data, isLoading, error, post } = useApiPost<any>(
    `stripe/create-checkout-session`
  );
  return { response: data, isLoading, error, post };
}

export function useSaveStripePayment() {
  const { data, isLoading, error, post } = useApiPost<any>(
    `payment/save-stripe-payment`
  );
  return { response: data, isLoading, error, post };
}

export function useFindStripeProducts() {
  const { data, isLoading, error, get } = useApiGet<any>('stripe/products');
  return { stripeProducts: data, isLoading, error, getStripeProducts: get };
}

export function useFindStripeSubscriptions() {
  const { data, isLoading, error, get } = useApiGet<any>('stripe/subscriptions');
  return { stripeSubscriptions: data, isLoading, error, getStripeSubscriptions: get };
}