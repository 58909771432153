import React from 'react';
import type { FC, ReactNode } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from '../hooks/useAuth';

interface ClientGuardProps {
  children?: ReactNode;
}

const AdminGuard: FC<ClientGuardProps> = ({ children }) => {
  const { isAuthenticated, user } = useAuth();

  if (!isAuthenticated) {
    return <Redirect to="/login" />;
  }

  if (!!user && !!user.roles && !!user.roles.find(r => r.name === 'Admin')) {
    return (
      <>
        {children}
      </>
    )
  } else {
    return null
  }
};

AdminGuard.propTypes = {
  children: PropTypes.node
};

export default AdminGuard;
