import { useEffect } from 'react';
import { useApiGet, useApiPut } from '../../common/api/Api.hooks';
import { UserSetting } from '../../types/user-setting';

export function useGetUserSetting(shouldFetchOnMount = false) {
  const { data, isLoading, error, get } = useApiGet<UserSetting>('settings');
  useEffect(() => {
    if (shouldFetchOnMount) {
      get();
    }
  }, [get, shouldFetchOnMount]);
  return { data, isLoading, error, get };
}

export function useUpdateUserSetting(userId: number) {
  const { data, isLoading, error, put } = useApiPut<UserSetting>(
    `settings/${userId}`
  );
  return { updated: data, isLoading, error, put };
}
