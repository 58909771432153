import { formatDate } from './formatter';
import { SubscriptionTypeDuration } from './../types/subscription-type';
import { User, UserRole } from '../types/user';
import moment from 'moment';

export const isSuperAdmin = (user: User | undefined | null) : boolean =>
  !!user?.roles?.some(role => role.name === UserRole.superAdmin);

export const isClient = (user: User | undefined | null) : boolean =>
  !!user?.roles?.some(role => role.name === UserRole.client);

export const withoutTime = (dateTime) => {
    var date = new Date(dateTime.getTime());
    date.setHours(0, 0, 0, 0);
    return date;
}

export const toUtcDate = (date: Date | any ) => {
  // 2021-09-29T00:00:00.000Z
  if (!date) return null;
  const year = new Date(date).getFullYear();
  const month = ("0" + (new Date(date).getMonth() + 1)).slice(-2);
  const day = ("0" + new Date(date).getDate()).slice(-2);
  return new Date(`${year}-${month}-${day}T00:00:00.000Z`);
}

export const calculateSubscriptionExpiredDate = (params: {
  startDate: Date | any, type: 'Week' | 'Month' | 'Year', duration: number
}): { isExpired: boolean, date?: any } => {
  const { type, duration } = {...params}
  const format = 'MMM DD, YYYY';
  const now = new Date();

  switch (type) {
    case "Week":
      let weekDate = new Date(params?.startDate)
      weekDate.setDate(weekDate.getDate() + duration * 7);
      return {
        date: moment(weekDate).format(format), isExpired: weekDate < now
      }
    case "Month":
      let monthDate = new Date(params?.startDate)
      monthDate.setMonth(monthDate.getMonth() + duration);
      return {
        date: moment(monthDate).format(format), isExpired: monthDate < now
      }
    case "Year":
      let yearDate = new Date(params?.startDate)
      yearDate.setFullYear(yearDate.getFullYear() + duration);
      return {
        date: moment(yearDate).format(format), isExpired: yearDate < now
      }
    default:
      return { isExpired: false };
  } 
}

export const getSubscriptionDetails = (subscription: any) => {
  const now = new Date();
  now.setHours(0,0,0,0);
  const expiredAt = new Date(subscription?.expiredAt);
  expiredAt.setHours(0,0,0,0);

  const remainingDays =  moment(expiredAt).diff(moment(now), 'days');

  return {
    isExpired: now > expiredAt,
    remainingDays : remainingDays < 0 ? 0 : remainingDays,
    isCancelled: !!subscription?.canceledAt,
    isCancellable: !!(subscription?.subscriptionId && subscription?.canceledAt === null)
  }
}

export const getLastSubscription = (subscriptions: any) => {
  let subscriptionTemp = subscriptions?.[0];
  subscriptions?.forEach(i => {
    if (i?.expiredAt > subscriptionTemp?.expiredAt ) {
      subscriptionTemp = i
    }
  })

  return subscriptionTemp;
}

export const getCurrentSubscription = (history: any[]) => {
  const current = history?.sort((a, b) => b.id - a.id)?.[0];
  const { isExpired, date } = calculateSubscriptionExpiredDate({
    startDate: current?.createdAt, 
    type: current?.subscriptionType?.type,
    duration: current?.subscriptionType?.duration
  });

  return {
    current,
    expiredDate: date,
    isExpired: isExpired || current?.canceledAt
  }
}

export interface CalculateMonthlyPaymentInLoanReturn {
  monthlyPayment: number;
  totalInterestAmount: number;
  totalLoan: number;
}

export interface CalculateMonthlyPaymentInLoanProps {
  loanAmount: number;
  numOfYears: number;
  interest: number;
}

export const calculateMonthlyPaymentInLoan = ({
    loanAmount,
    numOfYears,
    interest,
  } : CalculateMonthlyPaymentInLoanProps ) : CalculateMonthlyPaymentInLoanReturn => {
  let totalLoan: number;
  let totalInterestAmount: number;
  let monthlyPayment: number;
  const I = interest * 0.01;

  monthlyPayment = (((I / 12) * loanAmount)  / (1- (Math.pow (1+ (I / 12), (numOfYears * -12)))));
  totalInterestAmount = monthlyPayment * (numOfYears * 12) - loanAmount;
  totalLoan = loanAmount + totalInterestAmount;

  return {
    monthlyPayment,
    totalInterestAmount,
    totalLoan,
  }
}