import { Log } from './log';
import { UserSetting } from './user-setting';

export const roles = [
  { name: 'Admin', label: 'Admin' },
  { name: 'Client', label: 'Client' }
];

export interface Role {
  id?: number;
  name?: string;
}

export interface User {
  id: number;
  email: string;
  password?: string;
  firstName?: string;
  lastName?: string;
  verified?: boolean;
  readonly isDeleted?: boolean;
  readonly createdAt?: Date;
  readonly createdBy?: number;
  readonly modifiedAt?: Date;
  readonly modifiedBy?: number;
  roles?: Role[];
  userSetting?: UserSetting;
  isPasswordSet?: boolean;
  avatar?: any; // Remove this
  name?: any; // Remove this
  profilePhotoUrl?: any;
  subscriptionHistory?: any[];
  [key: string]: any;
}

export enum UserRole {
  superAdmin = 'SuperAdmin',
  admin = 'Admin',
  client = 'Client'
}

export interface ResetPassword {
  password: string;
  token: string;
}

export interface VerifyEmail {
  token: string;
}

export enum UserStatus {
  LoggedIn = 'logged-in',
  Trial = 'trial',
  Activated = 'activated',
  Cancelled = 'cancelled'
}

export interface UserLog extends Log {
  status: UserStatus;
}
