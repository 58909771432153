import React, { Suspense, Fragment, lazy } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import MainLayout from 'src/layouts/MainLayout';
import HomeView from 'src/views/home/HomeView';
import LoadingScreen from 'src/components/LoadingScreen';
import AuthGuard from 'src/guards/AuthGuard';
import GuestGuard from 'src/guards/GuestGuard';
import ClientGuard from './guards/ClientGuard';
import SuperAdminGuard from './guards/SuperAdminGuard';
import AdminGuard from './guards/AdminGuard';
import HomePageGuard from './guards/HomePageGuard';

type Routes = {
  exact?: boolean;
  path?: string | string[];
  guard?: any[];
  layout?: any;
  component?: any;
  routes?: Routes;
}[];

// tslint:disable-next-line: no-shadowed-variable
export const renderRoutes = (routes: Routes = []): JSX.Element => (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route, i) => {
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={props => {
              const Child = () => (
                <Layout>
                  {route.routes ? (
                    renderRoutes(route.routes)
                  ) : (
                    <Component {...props} />
                  )}
                </Layout>
              );
              if (!!route?.guard?.length) {
                return route?.guard?.map((Guard: any) => (
                  <Guard>
                    <Child />
                  </Guard>
                ));
              } else {
                return (
                  <Fragment>
                    <Child />
                  </Fragment>
                );
              }
            }}
          />
        );
      })}
    </Switch>
  </Suspense>
);

const routes: Routes = [
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('src/views/errors/NotFoundView'))
  },
  {
    exact: true,
    path: '/faqs',
    component: lazy(() => import('src/views/faqs'))
  },
  {
    exact: true,
    path: '/savings-analysis',
    component: lazy(() => import('src/views/savings-analysis'))
  },
  {
    exact: true,
    path: '/savings-analysis/mortgage',
    component: lazy(() => import('src/views/savings-analysis/DebtAnalysis'))
  },
  {
    exact: true,
    path: '/expired-subscription',
    component: lazy(() => import('src/views/subscription/ExpiredSubscriptionView'))
  },
  {
    exact: true,
    guard: [GuestGuard],
    path: '/login',
    component: lazy(() => import('src/views/auth/LoginView'))
  },
  {
    exact: true,
    path: '/login-unprotected',
    component: lazy(() => import('src/views/auth/LoginView'))
  },
  {
    exact: true,
    guard: [GuestGuard],
    path: '/register',
    component: lazy(() => import('src/views/auth/RegisterView'))
  },
  // {
  //   exact: true,
  //   path: '/register-unprotected',
  //   component: lazy(() => import('src/views/auth/RegisterView'))
  // },
  {
    exact: true,
    guard: [GuestGuard],
    path: '/password/recovery',
    component: lazy(() => import('src/views/auth/ForgotPasswordView'))
  },
  {
    exact: true,
    guard: [GuestGuard],
    path: '/password/reset',
    component: lazy(() => import('src/views/auth/PasswordResetView'))
  },
  {
    exact: true,
    path: '/verify-email',
    component: lazy(() => import('src/views/auth/VerifyEmailView'))
  },
  {
    exact: true,
    guard: [GuestGuard],
    path: '/invitation/:token',
    component: lazy(() => import('src/views/auth/InvitationView'))
  },
  {
    exact: true,
    guard: [AuthGuard],
    path: '/subscription/list',
    component: lazy(() => import('src/views/subscription/SubscriptionListView'))
  },
  {
    exact: true,
    // guard: [AuthGuard],
    path: '/subscription/success/:sessionId',
    component: lazy(() => import('src/views/subscription/SubscriptionSuccessView'))
  },
  {
    exact: true,
    // guard: [AuthGuard],
    path: '/subscription/canceled',
    component: lazy(() => import('src/views/subscription/CanceledSubscriptionView'))
  },
  {
    exact: true,
    path: '/terms-and-conditions',
    component: lazy(() => import('src/views/auth/TermsAndConditions'))
  },
  {
    path: '/portal',
    guard: [AuthGuard],
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/portal/account',
        component: lazy(() => import('src/views/account/AccountView'))
      },
      {
        exact: true,
        path: '/portal/calendar',
        guard: [ClientGuard],
        component: lazy(() => import('src/views/calendar/CalendarView'))
      },
      {
        exact: true,
        path: '/portal/map',
        guard: [ClientGuard],
        component: lazy(() => import('src/views/map'))
      },
      {
        exact: true,
        path: '/portal/admins',
        guard: [SuperAdminGuard],
        component: lazy(() => import('src/views/admin/AdminListView'))
      },
      {
        guard: [SuperAdminGuard],
        exact: true,
        path: '/portal/admins/dashboard',
        component: lazy(() => import('src/views/reports/DashboardView'))
      },
      {
        guard: [SuperAdminGuard],
        exact: true,
        path: '/portal/admins/:userId',
        component: lazy(() => import('src/views/admin/AdminDetailsView'))
      },
      {
        guard: [SuperAdminGuard],
        exact: true,
        path: '/portal/admins/:userId/edit',
        component: lazy(() => import('src/views/admin/AdminEditView'))
      },
      {
        guard: [SuperAdminGuard],
        exact: true,
        path: '/portal/invites',
        component: lazy(() => import('src/views/invites/InviteListView'))
      },
      {
        guard: [ClientGuard],
        exact: true,
        path: '/portal/clients/dashboard',
        component: lazy(() => import('src/views/reports/DashboardClientView'))
      },
      {
        guard: [ClientGuard],
        exact: true,
        path: '/portal/clients/referrals',
        component: lazy(() => import('src/views/referrals/ReferralListView'))
      },
      {
        guard: [SuperAdminGuard, AdminGuard],
        exact: true,
        path: '/portal/clients',
        component: lazy(() => import('src/views/client/ClientListView'))
      },
      {
        guard: [SuperAdminGuard, AdminGuard],
        exact: true,
        path: '/portal/clients/:userId',
        component: lazy(() => import('src/views/client/ClientDetailsView'))
      },
      {
        guard: [SuperAdminGuard, AdminGuard],
        exact: true,
        path: '/portal/clients/:userId/edit',
        component: lazy(() => import('src/views/client/ClientEditView'))
      },
      {
        guard: [SuperAdminGuard, AdminGuard],
        exact: true,
        path: '/portal/clients/:userId/login-as',
        component: lazy(() => import('src/views/client/ClientLoginAs'))
      },
      {
        guard: [SuperAdminGuard],
        exact: true,
        path: '/portal/subscription',
        component: lazy(() => import('src/views/admin/SubscriptionView'))
      },
      {
        guard: [SuperAdminGuard],
        exact: true,
        path: '/portal/payment',
        component: lazy(() => import('src/views/admin/PaymentView'))
      },
      {
        guard: [SuperAdminGuard],
        exact: true,
        path: '/portal/stripe/product',
        component: lazy(() => import('src/views/admin/Stripe/ProductView'))
      },
      {
        guard: [SuperAdminGuard],
        exact: true,
        path: '/portal/stripe/subscription',
        component: lazy(() => import('src/views/admin/Stripe/SubscriptionView'))
      },
      {
        guard: [ClientGuard],
        exact: true,
        path: '/portal/management/financial/information',
        component: lazy(() =>
          import('src/views/financial/financial-information/FinancialTabView')
        )
      },
      {
        guard: [ClientGuard],
        exact: true,
        path: '/portal/reports/transaction',
        component: lazy(() =>
          import('src/views/financial/transaction/TransactionList')
        )
      },
      {
        guard: [ClientGuard],
        exact: true,
        path: '/portal/reports/graphs',
        component: lazy(() =>
          import('src/views/reports/Graphs')
        )
      },
      {
        component: () => <Redirect to='/login' />
      }
    ]
  },
  {
    path: '*',
    layout: MainLayout,
    routes: [
      {
        exact: true,
        guard: [HomePageGuard],
        path: '/',
        component: HomeView
      },
      {
        exact: true,
        path: '/pricing',
        component: lazy(() => import('src/views/pricing/PricingView'))
      },
      {
        component: () => <Redirect to='/login' />
      }
    ]
  }
];

export default routes;
