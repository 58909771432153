import React, {
  createContext,
  useEffect,
  useReducer
} from 'react';
import type { FC, ReactNode } from 'react';
import jwtDecode from 'jwt-decode';
import type { User } from 'src/types/user';
import SplashScreen from 'src/components/SplashScreen';
import axios from 'src/utils/axios';
import { useApiGet } from '../common/api/Api.hooks';
import api from '../common/api/api';
import { UserContextType } from '../user/User.context';
import { UserSetting } from '../types/user-setting';
import useSettings from '../hooks/useSettings';
import { CentralAccount } from '../types/central-account';
import { PaginatedList } from '../common/PaginatedList.interface';

interface AuthState {
  isInitialised: boolean;
  isAuthenticated: boolean;
  user: User | null;
  adminId: number | null;
  centralAccounts: CentralAccount[] | null;
}

interface AuthContextValue extends AuthState {
  method: 'JWT',
  login: (user: User) => Promise<void>;
  loginAsAdmin: (user: User, adminId: number) => Promise<void>;
  logout: () => void;
  register: (user: Partial<User>) => Promise<void>;
  setCentralAccount: (centralAccounts: CentralAccount[]) => Promise<void>;
}

interface AuthProviderProps {
  children: ReactNode;
}

type InitialiseAction = {
  type: 'INITIALISE';
  payload: {
    isAuthenticated: boolean;
    user: User | null;
  };
};

type LoginAction = {
  type: 'LOGIN';
  payload: {
    user: User;
  };
};

type LoginAsAdminAction = {
  type: 'LOGIN_AS_ADMIN';
  payload: {
    user: User;
    adminId: number;
  };
};

type LogoutAction = {
  type: 'LOGOUT';
};

type RegisterAction = {
  type: 'REGISTER';
  payload: {
    user: User;
  };
};

type CentralAccountAction = {
  type: 'SET_CENTRAL_ACCOUNT';
  payload: {
    centralAccounts: CentralAccount[];
  };
};

type Action =
  | InitialiseAction
  | LoginAction
  | LoginAsAdminAction
  | LogoutAction
  | RegisterAction
  | CentralAccountAction;

const initialAuthState: AuthState = {
  isAuthenticated: false,
  isInitialised: false,
  user: null,
  adminId: null,
  centralAccounts: null,
};

const isValidToken = (accessToken: string): boolean => {
  if (!accessToken) {
    return false;
  }

  const decoded: any = jwtDecode(accessToken);
  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

const setSession = (accessToken: string | null): void => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem('accessToken');
    delete axios.defaults.headers.common.Authorization;
  }
};

const reducer = (state: AuthState, action: Action): AuthState => {
  switch (action.type) {
    case 'INITIALISE': {
      const { isAuthenticated, user } = action.payload;

      return {
        ...state,
        isAuthenticated,
        isInitialised: true,
        user,
      };
    }
    case 'LOGIN': {
      const { user } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        user,
      };
    }
    case 'LOGIN_AS_ADMIN': {
      const { user, adminId } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        user,
        adminId
      };
    }
    case 'LOGOUT': {
      api.removeAccessToken();
      return {
        ...state,
        isAuthenticated: false,
        user: null,
        adminId: null,
      };
    }
    case 'REGISTER': {
      const { user } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        user
      };
    }
    case 'SET_CENTRAL_ACCOUNT': {
      const { centralAccounts } = action.payload;

      return {
        ...state,
        centralAccounts
      };
    }
    default: {
      return { ...state };
    }
  }
};

const AuthContext = createContext<AuthContextValue>({
  ...initialAuthState,
  method: 'JWT',
  login: () => Promise.resolve(),
  loginAsAdmin: () => Promise.resolve(),
  logout: () => { },
  register: () => Promise.resolve(),
  setCentralAccount: () => Promise.resolve()
});

export const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialAuthState);
  const { data: fetchedUser, error, get: getCurrentUser } = useApiGet<UserContextType>(
    'auth/me'
  );
  const { data: userSetting, get: getUserSetting } = useApiGet<UserSetting>('settings');
  const { data: centralAccountResponse, get: getCentralAccounts } = useApiGet<PaginatedList<CentralAccount>>('central-accounts');
  const { settings, saveSettings } = useSettings();

  const login = async (user: User) => {
    dispatch({
      type: 'LOGIN',
      payload: {
        user
      }
    });
  };

  const loginAsAdmin = async (user: User, adminId: number) => {
    dispatch({
      type: 'LOGIN_AS_ADMIN',
      payload: {
        user,
        adminId
      }
    });
  };

  const logout = () => {
    setSession(null);
    dispatch({ type: 'LOGOUT' });
  };

  const register = async (user: User) => {
    if (user) {
      dispatch({
        type: 'REGISTER',
        payload: {
          user
        }
      });
    }
  };

  const setCentralAccount = async (centralAccounts: CentralAccount[]) => {
    dispatch({
      type: 'SET_CENTRAL_ACCOUNT',
      payload: { centralAccounts }
    });
  };

  useEffect(() => {
    if (fetchedUser) {
      getCentralAccounts({ ownerId: fetchedUser.id })
      dispatch({
        type: 'INITIALISE',
        payload: {
          isAuthenticated: true,
          user: fetchedUser
        }
      });
    }
  }, [fetchedUser])

  useEffect(() => {
    if (centralAccountResponse) {
      setCentralAccount(centralAccountResponse?.data)
    }
  }, [centralAccountResponse])

  useEffect(() => {
    if (userSetting) {
      saveSettings({ ...settings, ...userSetting, currency: userSetting.currencyCode })
    }
  }, [userSetting])

  useEffect(() => {
    if (error) {
      dispatch({
        type: 'INITIALISE',
        payload: {
          isAuthenticated: false,
          user: null,
        }
      });
    }
  }, [error])

  useEffect(() => {
    const initialise = async () => {
      try {
        getCurrentUser()
        getUserSetting()
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALISE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    };

    initialise();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!state.isInitialised) {
    return <SplashScreen />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'JWT',
        login,
        loginAsAdmin,
        logout,
        register,
        setCentralAccount,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;