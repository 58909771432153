import React from 'react';
import type { FC } from 'react';
import { Router, Route } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { create } from 'jss';
import rtl from 'jss-rtl';
import MomentUtils from '@date-io/moment';
import { SnackbarProvider } from 'notistack';
import {
  jssPreset,
  StylesProvider,
  ThemeProvider
} from '@material-ui/core';
import { TourProvider } from '@reactour/tour';
import "semantic-ui-css/semantic.min.css";
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { QueryParamProvider } from 'use-query-params';
import GlobalStyles from 'src/components/GlobalStyles';
import ScrollReset from 'src/components/ScrollReset';
import CookiesNotification from 'src/components/CookiesNotification';
import GoogleAnalytics from 'src/components/GoogleAnalytics';
import SettingsNotification from 'src/components/SettingsNotification';
import { AuthProvider } from 'src/contexts/JWTAuthContext';
import useSettings from 'src/hooks/useSettings';
import { createTheme } from 'src/theme';
import routes, { renderRoutes } from 'src/routes';
import UserProvider, { UserContext } from './user/User.context';
import { steps } from './steps';
import { AppProvider } from './contexts/AppContext';

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });
const history = createBrowserHistory();

const App: FC = () => {
  const { settings } = useSettings();

  const theme = createTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    theme: settings.theme
  });

  return (
    <TourProvider steps={steps} onClickMask={() => { }}>
      <ThemeProvider theme={theme}>
        <Router history={history}>
          <QueryParamProvider ReactRouterRoute={Route}>
            <StylesProvider jss={jss}>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <SnackbarProvider
                  dense
                  maxSnack={3}
                >
                  <AppProvider>
                    <AuthProvider>
                      {/* <UserProvider> */}
                      <>
                        <GlobalStyles />
                        <ScrollReset />
                        <GoogleAnalytics />
                        {/* <SettingsNotification /> */}
                        {renderRoutes(routes)}
                      </>
                      {/* </UserProvider> */}
                    </AuthProvider>
                  </AppProvider>
                </SnackbarProvider>
              </MuiPickersUtilsProvider>
            </StylesProvider>
          </QueryParamProvider>
        </Router>
      </ThemeProvider>
    </TourProvider>
  );
};

export default App;