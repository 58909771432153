import React from 'react';
import type { FC } from 'react';

interface LogoProps {
  [key: string]: any;
  light?: boolean
}

const Logo: FC<LogoProps> = (props) => {
  const src = props.light ? "/static/liberte-logo-light.png" : "/static/liberte-logo.png"
  return (
    <img
      alt="Logo"
      src={src}
      style={{ height: 30 }}
      {...props}
    />
  );
}

export default Logo;
