import React from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Link as RouterLink } from 'react-router-dom';
import {
  Box,
  Button,
  colors,
  Container,
  Typography,
  makeStyles,
  TextField,
  styled,
  Link
} from '@material-ui/core';
import type { Theme } from 'src/theme';

interface FooterProps {
  className?: string;
  contactRef?: any
}

const useStyles = makeStyles((theme: Theme) => ({
  footer1: {
    backgroundColor: theme.palette.primary.main,
    paddingTop: 10,
    paddingBottom: 10
  },
  footer2: {
    backgroundColor: colors.common.black,
    paddingTop: 10,
    paddingBottom: 10
  },
  header: {
    fontFamily: 'Poppins',
    fontWeight: 'bold',
    color: colors.common.white,
    marginBottom: 10
  },
  text: {
    fontFamily: 'Poppins',
    color: colors.common.white,
  },
  link: {
    fontFamily: 'Poppins',
    color: colors.common.white,
    marginBottom: 10,
    textDecoration: 'underline'
  },
  browseButton: {
    marginLeft: theme.spacing(2)
  }
}));

const CssTextField = styled(TextField)({
  width: 200, 
  '& label.Mui-focused': {
    color: 'white',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: 'white',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'white',
    },
    '&:hover fieldset': {
      borderColor: 'white',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'white',
    },
  },
  '& .MuiInputBase-input': {
    color: 'white',
  },
});
const Footer: FC<FooterProps> = ({ className, contactRef, ...rest }) => {
  const classes = useStyles();

  return (
    <>
      <div
        ref={contactRef}
        className={clsx(classes.footer1)}
      >
        <Container maxWidth="lg" >
          <Box display={'flex'} flexDirection={'row'} justifyContent={'center'} padding={5}>
            <Box flex={2} marginRight={5}>
              <Typography
                variant="h5"
                align="left"
                className={classes.header}
              >
                Contact
              </Typography>
              <Box display={'flex'} flexDirection={'row'}>
                <Box marginRight={5}>
                  <Box>
                    <Typography
                      variant="caption"
                      align="center"
                      className={classes.text}
                    >
                      473 E. Carnegie Drive, Suite 200 San Bernardino CA 92408
                    </Typography>
                  </Box>
                  <Box marginTop={2}>
                    <Typography
                      variant="caption"
                      align="center"
                      className={classes.text}
                    >
                      General Inquiries: 123-456-7890
                    </Typography>
                  </Box>
                </Box>
                <Box>
                  <Box>
                    <Typography
                      variant="caption"
                      align="center"
                      className={classes.text}
                    >
                      Sales: info@mysite.com
                    </Typography>
                  </Box>
                  <Box  marginTop={2}>
                    <Typography
                      variant="caption"
                      align="center"
                      className={classes.text}
                    >
                      Costumer Care: info@mysite.com
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box flex={1} marginRight={5}>
              <Typography
                variant="h5"
                align="left"
                className={classes.header}
              >
                Quick Links
              </Typography>
              <Box>
                <Box marginTop={2}>
                  {/* <Typography
                    variant="h6"
                    align="left"
                    className={classes.text}
                  >
                    Terms & Conditions
                  </Typography> */}
                  <Link
                    variant="h6"
                    color="inherit"
                    to="/terms-and-conditions"
                    component={RouterLink}
                    className={classes.link}
                  >
                     Terms & Conditions
                  </Link>
                </Box>
                <Box marginTop={2}>
                <Link
                    variant="h6"
                    color="inherit"
                    to="/terms-and-conditions"
                    component={RouterLink}
                    className={classes.link}
                  >
                    Privacy Policy
                  </Link>
                </Box>
              </Box>
            </Box>
            <Box flex={1} marginRight={5}>
              <Typography
                variant="h5"
                align="left"
                className={classes.header}
              >
                Follow
              </Typography>
              <Box>
                <Box>
                  <Typography
                    variant="caption"
                    align="left"
                    className={classes.text}
                  >
                    Sign up to get the latest news on our product.
                  </Typography>
                </Box>
                <Box display={'flex'} flexDirection={'row'} marginTop={1}>
                  <CssTextField focused label="Email" variant='outlined' margin='dense' />
                  <Box>
                    <Button size='medium' variant='contained' style={{ marginTop: 10, marginLeft: 5 }}>Subscribe</Button>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box flex={1}>
              <Typography
                variant="h5"
                align="left"
                className={classes.header}
              >
                Social Media
              </Typography>
              <Box marginTop={3}>
                <Box>
                  <Typography
                    variant="h6"
                    align="left"
                    className={classes.text}
                    style={{ marginBottom: 15 }}
                  >
                    LinkedIn
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    variant="h6"
                    align="left"
                    className={classes.text}
                    style={{ marginBottom: 15 }}
                  >
                    YouTube
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    variant="h6"
                    align="left"
                    className={classes.text}
                    style={{ marginBottom: 15 }}
                  >
                    Facebook
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Container>
      </div>
      <div
        className={clsx(classes.footer2)}
      >
        <Container maxWidth="md" >
          <Typography
            variant="body2"
            align="center"
            className={classes.text}
          >
            © 2022 by Tito Solutions
          </Typography>
        </Container>
      </div>
    </>
  );
};

Footer.propTypes = {
  className: PropTypes.string
};

export default Footer;
