import React from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Link as RouterLink } from 'react-router-dom';
import {
  Box,
  Button,
  Container,
  Typography,
  makeStyles
} from '@material-ui/core';
import type { Theme } from 'src/theme';
import Zoom from 'react-reveal/Zoom';

interface CalculateSavingsProps {
  className?: string;
  savingsAnalysisRef?: any;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    paddingTop: 50,
    paddingBottom: 50
  },
  title: {
    color: theme.palette.primary.main,
  },
  content: {
    color: theme.palette.primary.dark,
    fontWeight: 300,
    paddingTop: 30, 
    width:'80%',
    alignSelf: 'center',
    fontFamily: 'Poppins',
    textAlign: 'center',
    margin: 'auto'
  },
  browseButton: {
    marginLeft: theme.spacing(2)
  },
  image: {
    display: 'block',
    margin: 'auto',
    width: 700,
    '& > img': {
      maxWidth: '50%',
      height: 'auto',
    }
  }
}));

const CalculateSavings: FC<CalculateSavingsProps> = ({ className, savingsAnalysisRef, ...rest }) => {
  const classes = useStyles();

  return (
    <div
      ref={savingsAnalysisRef}
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Container maxWidth="md">
        <Box display={'flex'} flexDirection={'row'} justifyContent="center" marginTop={10}>
            <Box>
              <Typography
                className={clsx(classes.title)}
                variant="h2"
                align="center"
                style={{ fontWeight: 'bold' }}
                >    
                  Traditional vs Smart Payments
              </Typography>
            </Box>
          </Box>
          <Zoom>
            <Box flex={1} justifyContent='center' alignContent={'center'} alignItems='center'>
              <Typography
                className={clsx(classes.content)}
                variant="body1"
                align="center"
                color="textSecondary"
              >
                In a traditional mortgage loan the majority of your payments go straight to interest. Using our smart payment system you will see a drastic reduction in overall interest as well as quickly building equity in your home. Smart payments will be tailored based on your custom financial roadmap and will calculate the fastest payoff plan based on your real data. This gives you an easy to follow plan that will notify you of when you can afford smart payments getting you one step closer to financial freedom!
              </Typography>
            </Box>
            <Box flex={1} justifyContent='center' alignContent={'center'} alignItems='center'>
                <img
                  className={clsx(classes.image)}
                  src="/static/home/input financials.png"
                  />
            </Box>
            <Box flex={1} justifyContent='center' display={'flex'}  alignContent={'center'} alignItems='center' marginTop={5} marginBottom={10}>
              <Button
                  color="secondary"
                  component={RouterLink}
                  to="/savings-analysis"
                  variant="contained"
                  size="large"
                  style={{ color: '#fff',  margin: 'auto'}}
                >
                  Calculate your Savings
                </Button>
            </Box>
          </Zoom>
      </Container>
   
    </div>
  );
};

CalculateSavings.propTypes = {
  className: PropTypes.string
};

export default CalculateSavings;
