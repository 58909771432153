import React from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Button,
  Container,
  Typography,
  makeStyles
} from '@material-ui/core';
import type { Theme } from 'src/theme';
import Fade from 'react-reveal/Fade';

interface SuccessStoriesProps {
  className?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    paddingTop: 50,
    paddingBottom: 50
  },
  browseButton: {
    marginLeft: theme.spacing(2)
  },
  description: {
    fontWeight: 300,
    marginTop: 20 , 
    fontFamily: 'Poppins' ,  
    color: '#fff'
  }
}));

const stories = [
  {
    content: `Liberte is a powerful application that allows you to pay your mortgage and loans faster. By considering your current income and expenses, the application will help you pay your mortgage and loans strategically by recommending smart payments. So, instead of paying your house for 20-30 years, use Liberte to pay it off for only 7-10 years. Try it and you'll want to keep it forever.`,
    author:  'Alex',
    location: 'California'
  },
  {
    content: `Liberte is a powerful application that allows you to pay your mortgage and loans faster. By considering your current income and expenses, the application will help you pay your mortgage and loans strategically by recommending smart payments. So, instead of paying your house for 20-30 years, use Liberte to pay it off for only 7-10 years. Try it and you'll want to keep it forever.`,
    author:  'James',
    location: 'Boston'
  },
  {
    content: `Liberte is a powerful application that allows you to pay your mortgage and loans faster. By considering your current income and expenses, the application will help you pay your mortgage and loans strategically by recommending smart payments. So, instead of paying your house for 20-30 years, use Liberte to pay it off for only 7-10 years. Try it and you'll want to keep it forever.`,
    author:  'Marie',
    location: 'Maryland'
  }
]

const SuccessStories: FC<SuccessStoriesProps> = ({ className, ...rest }) => {
  const classes = useStyles();

  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Container maxWidth="lg">
        <Box>
          <Box flex={1} display={'flex'}  justifyContent="center" alignContent='center' alignItems={'center'}>
            <Box>
              <Typography
                variant="h1"
                align="center"
                color="textSecondary"
                style={{ fontWeight: 'bold', color: '#fff', marginBottom: 20 }}
                >    
                 Success Stories
              </Typography>
            </Box>
          </Box>
          <Box flex={1} display='flex' flexDirection='row'>
          <Fade cascade bottom>
            {stories.map(story => (
                <Box flex={1} margin={5}>
                  <Typography
                    className={classes.description}
                    variant="body1"
                    align="left"
                    color="textSecondary"
                  >
                    {story.content}
                  </Typography>
                  <Typography
                    className={classes.description}
                    variant="body1"
                    align="left"
                    color="textSecondary"
                  >
                    {story.author}
                  </Typography>
                  <Typography
                    className={classes.description}
                    variant="body1"
                    align="left"
                    color="textSecondary"
                  >
                    {story.location}
                  </Typography>
                </Box>
            ))}
            </Fade>
          </Box>
        </Box>
      </Container>
    </div>
  );
};

SuccessStories.propTypes = {
  className: PropTypes.string
};

export default SuccessStories;
