export const steps = [
  {
    selector: '.tour-step-1',
    content: 'This is your subscripton type.'
  },
  // {
  //   selector: '.tour-step-term',
  //   content: 'This is your loan term.'
  // },
  // {
  //   selector: '.tour-step-balance',
  //   content: 'This is your loan balance.'
  // },
  // {
  //   selector: '.tour-step-income-overview',
  //   content: 'Monthly income will display here'
  // },
  // {
  //   selector: '.tour-step-expense-overview',
  //   content: 'Monthly expense will display here'
  // },
  {
    selector: '.tour-step-central-account',
    content: 'This is your central account.'
  },
  {
    selector: '.tour-step-bank-account',
    content: 'This is my 3rd Step.'
  },
  {
    selector: '.tour-step-income',
    content: 'Add your income here'
  },
  {
    selector: '.tour-step-expense',
    content: 'Add your expenses here'
  },
  {
    selector: '.tour-step-consumer-debt',
    content: 'Add your debt here'
  },
  {
    selector: '.tour-step-create-transaction',
    content: 'Create a transaction here!'
  }

  // {
  //   selector: '.tour-step-pay-off-order',
  //   content: 'This is my 7th Step.'
  // }
];
