import React from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Link as RouterLink } from 'react-router-dom';
import {
  Box,
  Grid,
  Typography,
  makeStyles,
  Button,
} from '@material-ui/core';
import type { Theme } from 'src/theme';
import { Home as HomeIcon, Globe as GlobeIcon, Zap as ZapIcon  } from 'react-feather'
import 'react-slideshow-image/dist/styles.css';
import Bounce from 'react-reveal/Bounce';
import Zoom from 'react-reveal/Zoom';
import Fade from 'react-reveal/Zoom';

interface HeroProps {
  className?: string;
}

const slideImages = [
  {
    url: '/static/home/slider_image_1.jpg',
    title: 'Finally, your worry free life',
    title2: 'is just around the corner.',
    caption: 'Enjoy what matters most by taking away loans faster.'  
  },
  {
    url: '/static/home/slider_image_2.jpg',
    title: 'Life begins today',
    caption: 'Loans should not take your whole life away.',
    caption2: 'Start paying debts smarter and faster.'
  }
];


const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100%',
  },
  technologyIcon: {
    height: 40,
    margin: theme.spacing(1)
  },
  image: {
    borderBottomLeftRadius: 400,
    '& > img': {
      maxWidth: '90%',
      height: 'auto',
    }
  },
  shape: {
    position: 'absolute',
    top: 0,
    left: 0,
    '& > img': {
      maxWidth: '90%',
      height: 'auto'
    }
  },
  description: {
    fontWeight: 300,
  },
  slideContainer: {
    padding: 0,
  },
  titleBox: {
     [theme.breakpoints.down('md')]: {
      paddingLegt: 60,
    }
  },
  icon: {
    color: '#6e6e6e',
    marginRight: 10
  },
  iconBox: {
    marginRight: 30
  },
  oval: {
    width: 100,
    height: 100,
    borderRadius: 50,
    backgroundColor: "red",
  },
  getStartedButton: { 
    fontWeight: theme.typography.fontWeightBold,
    marginTop: 30,
    "&:hover": {
      color: '#fff',
    },
  }
}));

const Hero: FC<HeroProps> = ({ className, ...rest }) => {
  const classes = useStyles();

  const Slideshow = () => {
    return (
      <div className="slideContainer">
            <div>
              <Zoom>
              <div style={{ backgroundImage: `url(/static/home/bg1.jpg)`, backgroundRepeat: 'no-repeat', backgroundSize: 'contain', height: 600, backgroundPosition: 'right'}}>
                {/* <Oval/> */}
                <Grid
                  container
                  spacing={3}
                  style={{ height: '100%'}}
          
                >
                  <Grid
                    item
                    xs={12}
                    md={5}
                    lg={7}
                  >
                    <Box
                      flex={1}
                      display="flex"
                      flexDirection="column"
                      justifyContent="center"
                      alignItems={"center"}
                      style={{ height: '100%' }}
                      className={classes.titleBox}
                    >
                      <Box>
                        <Bounce left>
                          <Typography
                            variant="h1"
                            color="primary"
                            style={{ fontWeight: 'bolder' }}
                          >
                            Focus on what matters most.
                          </Typography>
                        </Bounce>
                          <Typography
                            variant="h1"
                            color="primary"
                            style={{ fontWeight: 'bolder' }}
                          >
                           
                          </Typography>
                        <Box>
                          <Bounce right>
                            <Typography
                              className={classes.description}
                              variant="body1"
                              color="textSecondary"
                              style={{ marginTop: 20, fontFamily: 'Poppins'}}
                            >
                              Debt can be overwhelming and can distract
                            </Typography>
                            <Typography
                              className={classes.description}
                              variant="body1"
                              color="textSecondary"
                              style={{ fontFamily: 'Poppins'}}
                            >
                              you from the things that really matter.
                            </Typography>
                              <Typography
                                className={classes.description}
                                variant="body1"
                                color="textSecondary"
                                style={{ fontFamily: 'Poppins'}}
                              >
                                Here at Liberte we help take the stress out of
                            </Typography>
                            <Typography
                                className={classes.description}
                                variant="body1"
                                color="textSecondary"
                                style={{ fontFamily: 'Poppins'}}
                              >
                                eliminating debt so you can get back to the 
                            </Typography>
                            <Typography
                                className={classes.description}
                                variant="body1"
                                color="textSecondary"
                                style={{ fontFamily: 'Poppins'}}
                              >
                               things in life that matter most. 
                            </Typography>
                          </Bounce>
                          <Fade>
                            <Button 
                              className={classes.getStartedButton}
                              component={RouterLink}
                              to="/register"
                              variant='contained' color='primary' 
                            >
                              Get Started
                            </Button>
                          </Fade>
                        </Box>
                        <Zoom bottom cascade>
                        <Box display={'flex'} flexDirection={'row'} marginTop={3}>
                          <Bounce duration={2000}>
                          <Box className={classes.iconBox}  display={'flex'} flexDirection={'row'} alignItems='center'>
                            <HomeIcon className={classes.icon} size={30} />
                            <Box>
                              <Typography
                                variant="body2"
                                color="textSecondary"
                                style={{ fontFamily: 'Poppins',  color: '#6e6e6e'}}
                              >
                                Savings
                              </Typography>
                              <Typography
                                variant="body2"
                                color="textSecondary"
                                style={{ fontFamily: 'Poppins',  color: '#6e6e6e'}}
                              >
                                Analysis
                              </Typography>
                            </Box>
                          </Box>
                          </Bounce>
                          <Bounce duration={2000}>
                          <Box className={classes.iconBox} display={'flex'} flexDirection={'row'} alignItems='center'>
                            <GlobeIcon className={classes.icon}  size={30} />
                            <Box>
                              <Typography
                                className={classes.description}
                                variant="body2"
                                color="textSecondary"
                                style={{ fontFamily: 'Poppins',  color: '#6e6e6e'}}
                              >
                                Multi-Currency
                              </Typography>
                              <Typography
                                className={classes.description}
                                variant="body2"
                                color="textSecondary"
                                style={{ fontFamily: 'Poppins',  color: '#6e6e6e'}}
                              >
                                Dashboard
                              </Typography>
                            </Box>
                          </Box>
                          </Bounce>
                          <Bounce duration={2000}>
                          <Box className={classes.iconBox} display={'flex'} flexDirection={'row'} alignItems='center'>
                            <ZapIcon className={classes.icon}  size={30} />
                            <Box>
                              <Typography
                                className={classes.description}
                                variant="body2"
                                color="textSecondary"
                                style={{ fontFamily: 'Poppins',  color: '#6e6e6e'}}
                              >
                                Smart
                              </Typography>
                              <Typography
                                className={classes.description}
                                variant="body2"
                                color="textSecondary"
                                style={{ fontFamily: 'Poppins',  color: '#6e6e6e'}}
                              >
                                Payments
                              </Typography>
                            </Box>
                          </Box>
                          </Bounce>
                        </Box>
                        </Zoom>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </div>
              </Zoom>
            </div>
            
      </div>
    )
};

  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
      <div>
        <Slideshow />
      </div>
    </div>
  );
};

Hero.propTypes = {
  className: PropTypes.string
};

export default Hero;
