export const APP_VERSION = '2.0.8';

export const SERVER_URL = process.env.REACT_APP_SERVER_URL;
export const PUBLIC_KEY = process.env.REACT_APP_PUBLIC_KEY;
export const CLIENT_URL = process.env.REACT_APP_CLIENT_URL;
export const RECAPTCHA_SITE_KEY = process.env.REACT_APP_CAPTCHA_SITE_KEY || '6LcnXbMfAAAAACXq_WPxzB7NM48cnb6XPwqJmsBt';

export const ENABLE_REDUX_DEV_TOOLS = true;

export const THEMES = {
  LIGHT: 'LIGHT',
  ONE_DARK: 'ONE_DARK',
  UNICORN: 'UNICORN'
};

export const VIDEO_PRESENTATION_URL =
  'https://res.cloudinary.com/dgeuyzmmy/video/upload/v1615363177/video/6720EL8dvOfp4s_beta_toiz9q.mp4';
