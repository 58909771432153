import React from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Button,
  Container,
  Typography,
  makeStyles
} from '@material-ui/core';
import type { Theme } from 'src/theme';
import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';

interface KeyFeaturesProps {
  className?: string;
  productRef?: any
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: '#e8e6e6',
    paddingTop: 50,
    paddingBottom: 50
  },
  title: {
    color: theme.palette.primary.main,
    marginTop: 20 , fontFamily: 'Poppins',
    fontWeight: 'bold'
  },
  browseButton: {
    marginLeft: theme.spacing(2)
  },
  image: {
    width: 300,
    '& > img': {
      maxWidth: '50%',
      height: 'auto',
    }
  },
  image2: {
    width: 600,
    '& > img': {
      maxWidth: '50%',
      height: 'auto',
    }
  },
}));

const features = [
  {
    title:  'Spotlight',
    content: `Spotlight allows you to see your debt standing and future savings at a glance. This helps you to be informed of your debt status and what's ahead without the need of pulling a report.`,
    location: 'California'
  },
  {
    title:  'Multi-Currency',
    content: `The software can be used by users from different countries by allowing them to change to their currency.`,
    location: 'Boston'
  },
  {
    title:  'Insights & Reports',
    content: `Users will be able to view their traditional vs accelerated payment paths. This helps them forecast their loans and debts, while being able to view how much they save on interest.`,
    location: 'Maryland'
  }
]

const KeyFeatures: FC<KeyFeaturesProps> = ({ className, productRef, ...rest }) => {
  const classes = useStyles();

  return (
    <div
      ref={productRef}
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Container maxWidth="lg">
        <Box>
          <Box flex={1} display={'flex'} flexDirection='row' justifyContent="center" alignContent='center' alignItems={'center'}>
            <Box>
              <Typography
                variant="h1"
                align="center"
                color="textSecondary"
                style={{ fontWeight: 'bold', color: '#6e6e6e', marginBottom: 20 }}
                >    
                 What you get is more than what you see.
              </Typography>
            </Box>
          </Box>
          <Box flex={1} display={'flex'} flexDirection='row' justifyContent="center" alignContent='center' alignItems={'center'}>
            <Box>
              <Typography
                variant="body1"
                align="center"
                color="textSecondary"
                style={{ color: '#6e6e6e' }}
                >    
                 Liberte's benefits exceeds its features. 
              </Typography>     
            </Box>
          </Box>
          <Fade right> 
            <Box display='flex' flexDirection='row' marginTop={10} marginBottom={10}>
                <Box flex={3} margin={5}>
                  <Box justifyContent={'center'}>
                    <img
                      className={clsx(classes.image2)}
                      src="/static/home/input financials.png"
                    />
                  </Box>
                </Box>
                <Box flex={2} display='flex' flexDirection={'column'} justifyContent='center' alignItems={'center'} margin={10}>
                  <Typography
                    className={clsx(classes.title)}
                    variant="h1"
                    align="left"
                  >
                    Manage your liabilities in one platform.
                  </Typography>
                  <Typography
                    variant="body1"
                    align="left"
                    color="textSecondary"
                    style={{ marginTop: 20 , fontFamily: 'Poppins', fontWeight: 300,  color: '#6e6e6e' }}
                  >
                    With Liberte your finances are organized in a way that makes understanding them significantly easier. With a macro view and the ability to see where your money is going your liabilities have never been easier to manage. 
                  </Typography>
                </Box>
            </Box>
          </Fade>
          <Fade left> 
            <Box display='flex' flexDirection='row' marginTop={10} marginBottom={10}> 
              <Box flex={2} display='flex' flexDirection={'column'} justifyContent='center' alignItems={'center'} margin={10}>
                <Typography
                  className={clsx(classes.title)}
                  variant="h1"
                  align="left"
                >
                  Keep track of your payment schedule.
                </Typography>
                <Typography
                  variant="body1"
                  align="left"
                  color="textSecondary"
                  style={{ marginTop: 20 , fontFamily: 'Poppins', fontWeight: 300, color: '#6e6e6e' }}
                >
                  With customizable notifications your payments have never been easier to track. While the notifications mean that you will be aware of every payment our customized reports allow you to go back into your financial history and track exactly how you achieved your financial freedom. 
                </Typography>
              </Box>
              <Box flex={3} margin={5}>
                <Box justifyContent={'center'}>
                  <img
                    className={clsx(classes.image2)}
                    src="/static/home/input financials.png"
                  />
                </Box>
              </Box>
            </Box>
          </Fade>
          <Fade right>
            <Box display='flex' flexDirection='row' marginTop={10} marginBottom={5}>
                <Box flex={3} margin={5}>
                  <Box justifyContent={'center'}>
                    <img
                      className={clsx(classes.image2)}
                      src="/static/home/input financials.png"
                    />
                  </Box>
                </Box>
                <Box flex={2} display='flex' flexDirection={'column'} justifyContent='center' alignItems={'center'} margin={10}>
                  <Typography
                    className={clsx(classes.title)}
                    variant="h1"
                    align="left"
                  >
                    Shorten Loan Periods with Smart Payments
                  </Typography>
                  <Typography
                    variant="body1"
                    align="left"
                    color="textSecondary"
                    style={{ marginTop: 20 , fontFamily: 'Poppins',fontWeight: 300, color: '#6e6e6e' }}
                  >
                    With our smart payment system our community has saved hundreds of thousands of dollars in interest alongside paying off their mortgage and consumer loans in significantly less time than their previous paths had planned for them. Smart payments are your ticket to financial freedom and getting on track to the goals in life that matter most to you.
                  </Typography>
                </Box>
            </Box>
          </Fade>
        </Box>
        <Box marginTop={5}>
            <Box flex={1} display={'flex'}  justifyContent="center" alignContent='center' alignItems={'center'}>
              <Box>
                <Typography
                  variant="h1"
                  align="center"
                  color="textSecondary"
                  style={{ fontWeight: 'bold', color: '#6e6e6e', marginBottom: 40 }}
                  >    
                  Other Key Features
                </Typography>
              </Box>
            </Box>
            <Box flex={1} display='flex' flexDirection='row'>
              <Zoom>
              {features.map(story => (
                <Box flex={1} margin={5}>
                  <Box justifyContent={'center'}>
                  <img
                    className={clsx(classes.image)}
                    src="/static/home/input financials.png"
                  />
                </Box>
                  <Typography
                    className={clsx(classes.title)}
                    variant="h1"
                    align="center"
                  >
                    {story.title}
                  </Typography>
                  <Typography
                    variant="body1"
                    align="left"
                    color="textSecondary"
                    style={{ marginTop: 20 , fontFamily: 'Poppins', fontWeight: 300, color: '#6e6e6e' }}
                  >
                    {story.content}
                  </Typography>
                </Box>
              ))}
            </Zoom>
            </Box>
          </Box>
      </Container>
    </div>
  );
};

KeyFeatures.propTypes = {
  className: PropTypes.string
};

export default KeyFeatures;
