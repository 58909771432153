import React, { useState, useEffect, useContext } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { XCircle as CloseIcon } from 'react-feather'
import type { FC } from 'react';
import { PlusCircle as PlusCircleIcon, LogOut as LogOutIcon  } from 'react-feather'
import {
  Box,
  Button,
  Typography,
  makeStyles,
  SvgIcon,
  IconButton,
  CardContent,
  Divider,
  LinearProgress,
  Card,
  CardActionArea,
  CardActions,
  Container,
} from '@material-ui/core';
import { useCreateStripeCheckoutSession, useFindStripeProducts, useFindSubscriptionHistory, useFindSubscriptionTypes } from 'src/views/admin/SubscriptionView/subscription.hooks';
import { getCurrentSubscription, getLastSubscription, getSubscriptionDetails } from 'src/common/utils';
import { User } from 'src/types/user';
import useAuth from '../../../../hooks/useAuth';

interface ChooseSubscriptionModalProps {
  className?: string;
  user: User;
  autoShow?: boolean;
  showButton?: boolean;
  onCreate?: (data: any) => void;
}

const useStyles = makeStyles(() => ({
  root: {}
}));

const ChooseSubscriptionModal: FC<ChooseSubscriptionModalProps> = ({ className, autoShow = false, showButton = false, user, onCreate, ...rest }) => {
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const { logout } = useAuth();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [subscriptionTypes, setSubscriptionTypes] = useState<any>();
  const { subscriptionTypes: resultList, get: getSubscriptionTypes } = useFindSubscriptionTypes();

  const { response, isLoading, post } = useCreateStripeCheckoutSession();
  const { response: history, get: getHistory } = useFindSubscriptionHistory();

  useEffect(() => {
    if(history) {
      if (history?.data?.length < 1) {
        setOpen(true);
        return;
      }
      const latestSubscription = getLastSubscription(history?.data);
      const { isExpired } = getSubscriptionDetails(latestSubscription);
      if (isExpired) {
        getSubscriptionTypes();
      }
    }
  }, [history]);

  useEffect(() => {
    if (resultList) {
      setSubscriptionTypes(resultList)
      setOpen(true);
    }
  }, [resultList]);

  useEffect(() => {
    if (subscriptionTypes) {
      setOpen(true);
    }
  }, [subscriptionTypes]);

  useEffect(() => {
    if (response?.url) {
      window.document.location.replace(response?.url)
    }
  }, [response?.url]);
 
  useEffect(() => {
    const params = {
      page: 0,
      limit:  10,
      order: '',
      ownerId: user?.id
    } as any;
    autoShow && getHistory(params);
  }, []);

  const onSubscribe = (subscriptionType: any) => {
    post({ subscriptionType })
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = async (): Promise<void> => {
    try {
      handleClose();
      await logout();
      history.push('/login');
    } catch (err) {
      console.error(err);
    }
  };

  const ButtonView = () => (
    <Button
      size="small"
      color="secondary"
      variant="contained"
      onClick={handleClickOpen}
      style={{ marginTop: 7, marginRight: 5 }}
      startIcon={
        <SvgIcon fontSize="small">
          <PlusCircleIcon />
        </SvgIcon>
      }
    >
      Add Subscription
    </Button>
  );

  const ContentView = () => (
    <Box style={{ marginTop: 0 }}>
      <CardContent>
      <Container maxWidth="md" style={{ 
        borderRadius: 10, 
        padding: 30, 
        // background: 'rgb(245 245 245 / 84%)' 
        }}>
        <Typography
          align="center"
          color='primary'
          // variant={mobileDevice ? 'h4' : 'h1'}
          style={{ fontSize: 45 }}
        >
          Choose your Subscription
        </Typography>
        <Box
          mt={6}
          display="flex"
          justifyContent="center"
        >
          { subscriptionTypes?.map(item => {
            return(
              <Card style={{ width: 300, marginRight: 20}}>
                <CardActionArea style={{ height: 100 }} onClick={() => onSubscribe(item)}>
                  <CardContent>
                    <Typography gutterBottom color="primary"  variant="h4" component="div">
                      {item.title}
                    </Typography>
                    <Typography variant="body2">
                      {item.description}
                      {/* {formatCurrency(item.price, item.currencyCode)}/{item.type} */}
                    </Typography>
                  </CardContent>
                </CardActionArea>
                <CardActions>
                  <Button size="small" color="primary" onClick={() => onSubscribe(item)}>
                    Subscribe
                  </Button>
                </CardActions>
              </Card>
            )
          })}
        </Box>
      </Container>
      </CardContent>
    </Box>
  );

  const LogoutButton = () => (
    <Box display='flex' alignItems={'center'} justifyContent={'center'}>
      <Button
        size="small"
        color="secondary"
        variant="outlined"
        onClick={handleLogout}
        style={{ marginBottom: 5 }}
        startIcon={
          <SvgIcon fontSize="small">
            <LogOutIcon />
          </SvgIcon>
        }
      >
        Logout
      </Button>
    </Box>
  )

  return (
    <div>
      { showButton && <ButtonView />}
      <Dialog
        fullScreen={fullScreen}
        open={open}
        maxWidth='lg'
        aria-labelledby='responsive-dialog-title'
      >
        <DialogContent style={{ backgroundColor: '#fbfbfb' }}>
          { showButton && (
          <Box display='flex' justifyContent='space-between' alignItems='flex-start'>
            <Typography
              color="textPrimary"
              variant="h3"
              gutterBottom
            >
            </Typography>
            <IconButton size='medium' onClick={handleClose} style={{ top: -5, right: -10 }}>
              <SvgIcon fontSize="small">
                <CloseIcon />
              </SvgIcon>
            </IconButton>
          </Box>
          )}
          <ContentView />
          <LogoutButton />
        </DialogContent>
        <DialogActions />
        { isLoading && <LinearProgress /> }
      </Dialog>
    </div>
  );
};

export default ChooseSubscriptionModal;
