import React, {
  createContext,
  useEffect,
  useReducer,
  useRef
} from 'react';
import type { FC, ReactNode } from 'react';
import SplashScreen from 'src/components/SplashScreen';

interface AppContextValue {
  pricingRef?: any
  scrollToPrice?: any;
  productRef?: any
  scrollToProduct?: any;
  faqRef?: any
  scrollToFaq?: any;
  savingsAnalysisRef?: any
  scrollToSavingsAnalysis?: any;
  contactRef?: any
  scrollToContact?: any;
}

interface AppProviderProps {
  children: ReactNode;
}

const AppContext = createContext<AppContextValue>({
  pricingRef: () => Promise.resolve(),
  scrollToPrice: () => Promise.resolve(),
  productRef: () => Promise.resolve(),
  scrollToProduct: () => Promise.resolve(),
  faqRef: () => Promise.resolve(),
  scrollToFaq: () => Promise.resolve(),
  savingsAnalysisRef: () => Promise.resolve(),
  scrollToSavingsAnalysis: () => Promise.resolve(),
  contactRef: () => Promise.resolve(),
  scrollToContact: () => Promise.resolve(),
});

export const AppProvider: FC<AppProviderProps> = ({ children }) => {
  const pricingRef = useRef(null);
  const scrollToPrice = () => pricingRef.current.scrollIntoView({behavior: 'smooth'});
  const productRef = useRef(null);
  const scrollToProduct = () => productRef.current.scrollIntoView({behavior: 'smooth'});
  const faqRef = useRef(null);
  const scrollToFaq = () => faqRef.current.scrollIntoView({behavior: 'smooth'});
  const savingsAnalysisRef = useRef(null);
  const scrollToSavingsAnalysis = () => savingsAnalysisRef.current.scrollIntoView({behavior: 'smooth'})
  const contactRef = useRef(null);
  const scrollToContact = () => contactRef.current.scrollIntoView({behavior: 'smooth'})

  return (
    <AppContext.Provider
      value={{
        pricingRef,
        scrollToPrice,
        productRef,
        scrollToProduct,
        faqRef,
        scrollToFaq,
        savingsAnalysisRef,
        scrollToSavingsAnalysis,
        contactRef,
        scrollToContact
      }}
    >
      {children}
    </AppContext.Provider>
  );

}

export default AppContext;