import React from 'react';
import type { FC, ReactNode } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from '../hooks/useAuth';

interface GuestGuardProps {
  children?: ReactNode;
}

const GuestGuard: FC<GuestGuardProps> = ({ children }) => {
  const { isAuthenticated, user } = useAuth();

  if (isAuthenticated) {
    if (user) {
      if (user && user.roles) {
        if (user.roles.find(r => r.name === 'SuperAdmin'))
          return <Redirect to="/portal/admins/dashboard" />;
        else if (user.roles.find(r => r.name === 'Admin'))
          return <Redirect to="/portal/clients" />;
        else if (user.roles.find(r => r.name === 'Client'))
          return <Redirect to="/portal/clients/dashboard" />;
      } else {
        return <Redirect to="/login" />;
      }
    }
  }

  return (
    <>
      {children}
    </>
  );
};

GuestGuard.propTypes = {
  children: PropTypes.node
};

export default GuestGuard;
