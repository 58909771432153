import React, { useState } from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Button,
  Container,
  Typography,
  makeStyles,
  colors,
  IconButton,
  Divider,
  Link
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
// import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import type { Theme } from 'src/theme';
import Fade from 'react-reveal/Fade';
interface InstructionsProps {
  className?: string;
  faqRef?: any
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: colors.common.white,
    paddingTop: 20,
    paddingBottom: 20
  },
  browseButton: {
    marginLeft: theme.spacing(2)
  },
  title: {
    color: theme.palette.primary.main,
    marginTop: 0 , fontFamily: 'Poppins',
    fontWeight: 'bold'
  },
  image: {
    width: 600,
    '& > img': {
      maxWidth: '50%',
      height: 'auto',
    }
  },
  content: {
    color: '#6e6e6e',
    marginTop: 1 , fontFamily: 'Poppins',
  },
  box: {
    marginBottom: 20
  },
  devider: {
    marginTop: 20,
    marginBottom: 20,
  },
  link: {
    fontFamily: 'Poppins',
    marginBottom: 10,
    textDecoration: 'underline'
  },
}));

const Faq: FC<InstructionsProps> = ({ className, faqRef, ...rest }) => {
  const classes = useStyles();
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false)
  const [show3, setShow3] = useState(false)

  return (
    <div
      ref={faqRef}
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Container maxWidth="lg">
        <Fade>
        <Box display='flex' flexDirection='row' marginTop={10} marginBottom={10}> 
            <Box flex={2} display='flex' flexDirection={'column'} margin={10}>
              <Box className={clsx(classes.box)}>
                <Box display={'flex'} flexDirection={'row'}>
                  <Typography
                    className={clsx(classes.title)}
                    variant="h3"
                    align="left"
                  >
                    How does Liberte accelerate my mortgage payment?
                  </Typography>
                  <Box>
                    <IconButton 
                      onClick={() => {
                        setShow1(!show1)
                        setShow2(false)
                        setShow3(false)
                      }} 
                      size='small'
                    >
                      { show1 ? <KeyboardArrowUpIcon />  : <KeyboardArrowDownIcon />}
                    </IconButton>
                  </Box>
                </Box>
                {show1 && (
                  <Box>
                    <Typography
                      className={clsx(classes.content)}
                      variant="body1"
                      align="left"
                      style={{ marginTop: 20, marginBottom: 20}}
                    >
                      To add a new FAQ follow these steps:
                    </Typography>
                    <Typography className={clsx(classes.content)}>
                      1. Click "
                      <Link
                        variant="h6"
                        color="inherit"
                        to="/faqs"
                        component={RouterLink}
                        className={classes.link}
                      >
                        Manage FAQs
                      </Link>
                      " button
                    </Typography>
                    <Typography className={clsx(classes.content)}>
                      2. From your site’s dashboard you can add, edit and manage all your questions and answers
                    </Typography>
                    <Typography className={clsx(classes.content)}>
                      3. Each question and answer should be added to a category
                    </Typography>
                    <Typography className={clsx(classes.content)}>
                      4. Save and publish.
                    </Typography>
                  </Box>
                )}
                <Divider className={clsx(classes.devider)}/>
              </Box>
              <Box className={clsx(classes.box)}>
                <Box display={'flex'} flexDirection={'row'}>
                  <Typography
                    className={clsx(classes.title)}
                    variant="h3"
                    align="left"
                  >
                    Are there requirements to use Liberte?
                  </Typography>
                  <Box>
                    <IconButton 
                      onClick={() => {
                        setShow1(false)
                        setShow2(!show2)
                        setShow3(false)
                      }} 
                      size='small'
                    >
                      { show2 ? <KeyboardArrowUpIcon />  : <KeyboardArrowDownIcon />}
                    </IconButton>
                  </Box>
                </Box>
                {show2 && (
                  <Box>
                    <Typography
                      className={clsx(classes.content)}
                      variant="body1"
                      align="left"
                      style={{ marginTop: 20, marginBottom: 20}}
                    >
                      Yes. To add media follow these steps:
                    </Typography>
                    <Typography className={clsx(classes.content)}>
                      1. Enter the app’s Settings
                    </Typography>
                    <Typography className={clsx(classes.content)}>
                      2. Click on the "
                      <Link
                        variant="h6"
                        color="inherit"
                        to="/faqs"
                        component={RouterLink}
                        className={classes.link}
                      >
                        Manage FAQs
                      </Link>
                      " button
                    </Typography>
                    <Typography className={clsx(classes.content)}>
                      3. Select the question you would like to add media to
                    </Typography>
                    <Typography className={clsx(classes.content)}>
                      4. When editing your answer click on the camera, video, or GIF icon
                    </Typography>
                    <Typography className={clsx(classes.content)}>
                    5. Add media from your library.
                    </Typography>
                  </Box>
                )}
                <Divider className={clsx(classes.devider)}/>
              </Box>
              <Box className={clsx(classes.box)}>
                <Box display={'flex'} flexDirection={'row'}>
                  <Typography
                    className={clsx(classes.title)}
                    variant="h3"
                    align="left"
                  >
                    Can I also accelerate my credit card debt or other loans?
                  </Typography>
                  <Box>
                   <IconButton 
                      onClick={() => {
                        setShow1(false)
                        setShow2(false)
                        setShow3(!show3)
                      }} 
                      size='small'
                    >
                      { show3 ? <KeyboardArrowUpIcon />  : <KeyboardArrowDownIcon />}
                    </IconButton>
                  </Box>
                </Box>
                {show3 && (
                  <Box>
                    <Typography
                      className={clsx(classes.content)}
                      variant="body1"
                      align="left"
                      style={{ marginTop: 20, marginBottom: 20}}
                    >
                      You can edit the title from the Settings tab in the app. If you don’t want to display the title, simply disable the Title under “Info to Display”.
                    </Typography>
                  </Box>
                )}
                <Divider className={clsx(classes.devider)}/>
              </Box>
            </Box>
            <Box flex={3} margin={5}>
              <Box justifyContent={'center'}>
                <img
                  className={clsx(classes.image)}
                  src="/static/home/input financials.png"
                />
              </Box>
            </Box>
          </Box>
        </Fade>
      </Container>
    </div>
  );
};

Faq.propTypes = {
  className: PropTypes.string
};

export default Faq;
